import React, { Suspense, useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import SEO from "./Components/Seo";
import GoogleAnalyticsTracker from "./GoogleAnalytics/GoogleAnalyticsTracker";

import { isAdminAuthenticated, isUserAuthenticated } from "./auth";
import ProtectedRoute from "./ProtectedRoute";

import "./App.css";
import "./index.css";






const Home = React.lazy(() => import("./Pages/Home/Main"));
const CaseStudy = React.lazy(() => import("./Pages/portfolio/caseStudy"));
const CaseStudyDetail = React.lazy(() =>
  import("./Pages/portfolio/CaseStudyDetail")
);
const About = React.lazy(() => import("./Pages/About/Main"));
const Faqs = React.lazy(() => import("./Pages/Faqs/Faqs"));
const Contact = React.lazy(() => import("./Pages/Contact/Main"));
const Terms = React.lazy(() => import("./Pages/Legal/Terms"));
const Privacy = React.lazy(() => import("./Pages/Legal/Privacy"));
const PayLaterAgreement = React.lazy(() => import("./Pages/Legal/PayLaterAgreement"));

//Login & Signup
const Login = React.lazy(() => import("./loginform/Login"));
const Register = React.lazy(() => import("./loginform/Register"));
const ForgotPassword = React.lazy(() => import("./loginform/ForgotPassword"));

const Price = React.lazy(() => import("./Pricing/Price"));

// Funnels
const Sale = React.lazy(() => import("./Funnel/sale/sale"));
const PayLaterSales = React.lazy(() => import("./Funnel/sale/PayLaterSale"));

const Package = React.lazy(() => import("./Funnel/Package/package"));
const Thankyou = React.lazy(() => import("./Funnel/Thanks/Thankyou"));

// const Tony = React.lazy(() => import("./Funnel/TimesOfNewYork/tont"));
// const TikTokSale = React.lazy(() => import("./Funnel/Package/tiktok"));

// Admin Panel
const AdminArea = React.lazy(() => import("./AdminArea/AdminArea"));

// Client Area
const UserArea = React.lazy(() => import("./UserArea/UserArea"));


const NotFound = React.lazy(() => import("./Pages/NotFound"));


function App() {
  useEffect(() => {
    const currentPath = window.location.pathname;
    const currentHash = window.location.hash;

    if (!currentHash && currentPath !== "/") {
      window.location.replace(`/#${currentPath}`);
    }
  }, []);
  
  return (
    <HelmetProvider>
      <div className="App">
        <Router>
          <GoogleAnalyticsTracker>
            <Routes>
              {/* Home */}
              <Route
                path="/"
                element={
                  <Suspense
                    fallback={
                      <div className="loader-container">
                        <div className="nb-spinner"></div>
                      </div>
                    }
                  >
                    <SEO
                      title="Guaranteed Press Coverage"
                      description="Welcome to our home page."
                      keywords="home, main page"
                    />
                    <Home />
                  </Suspense>
                }
              />

              {/* Case Studies */}
              <Route
                path="/casestudy"
                element={
                  <Suspense
                    fallback={
                      <div className="loader-container">
                        <div className="nb-spinner"></div>
                      </div>
                    }
                  >
                    <SEO
                      title="Case Studies"
                      description="View our success stories and case studies."
                      keywords="case studies, success stories, projects"
                    />
                    <CaseStudy />
                  </Suspense>
                }
              />
              <Route
                path="/casestudy/:slug"
                element={
                  <Suspense
                    fallback={
                      <div className="loader-container">
                        <div className="nb-spinner"></div>
                      </div>
                    }
                  >
                    <SEO
                      title="Case Study"
                      description="Detailed view of our success story."
                      keywords="case study, project detail, success story"
                    />
                    <CaseStudyDetail />
                  </Suspense>
                }
              />

              {/* About and FAQs */}
              <Route
                path="/about"
                element={
                  <Suspense
                    fallback={
                      <div className="loader-container">
                        <div className="nb-spinner"></div>
                      </div>
                    }
                  >
                    <SEO
                      title="About Us"
                      description="Learn more about us."
                      keywords="about, company"
                    />
                    <About />
                  </Suspense>
                }
              />
              <Route
                path="/faqs"
                element={
                  <Suspense
                    fallback={
                      <div className="loader-container">
                        <div className="nb-spinner"></div>
                      </div>
                    }
                  >
                    <SEO
                      title="FAQs"
                      description="Frequently Asked Questions"
                      keywords="faqs, frequently asked questions"
                    />
                    <Faqs />
                  </Suspense>
                }
              />

              {/* Contact, Terms, and Privacy */}
              <Route
                path="/contact"
                element={
                  <Suspense
                    fallback={
                      <div className="loader-container">
                        <div className="nb-spinner"></div>
                      </div>
                    }
                  >
                    <SEO
                      title="Contact Us"
                      description="Get in touch with us."
                      keywords="contact, reach us"
                    />
                    <Contact />
                  </Suspense>
                }
              />
              <Route
                path="/terms"
                element={
                  <Suspense
                    fallback={
                      <div className="loader-container">
                        <div className="nb-spinner"></div>
                      </div>
                    }
                  >
                    <SEO
                      title="Terms of Service"
                      description="Read our terms."
                      keywords="terms, policy"
                    />
                    <Terms />
                  </Suspense>
                }
              />
              <Route
                path="/privacy"
                element={
                  <Suspense
                    fallback={
                      <div className="loader-container">
                        <div className="nb-spinner"></div>
                      </div>
                    }
                  >
                    <SEO
                      title="Privacy Policy"
                      description="Learn about our privacy practices."
                      keywords="privacy, data protection"
                    />
                    <Privacy />
                  </Suspense>
                }
              />


<Route
                path="/paylateragreement"
                element={
                  <Suspense
                    fallback={
                      <div className="loader-container">
                        <div className="nb-spinner"></div>
                      </div>
                    }
                  >
                    <SEO
                      title="Pay Later Agreement"
                      description="Learn about our Pay Later Policy."
                      keywords="Pay Later Policy"
                    />
                    <PayLaterAgreement/>
                  </Suspense>
                }
              />




              {/* Auth */}
              <Route
                path="/login"
                element={
                  <Suspense
                    fallback={
                      <div className="loader-container">
                        <div className="nb-spinner"></div>
                      </div>
                    }
                  >
                    <SEO
                      title="Login"
                      description="Access your account."
                      keywords="login, sign in"
                    />
                    <Login />
                  </Suspense>
                }
              />
              <Route
                path="/register"
                element={
                  <Suspense
                    fallback={
                      <div className="loader-container">
                        <div className="nb-spinner"></div>
                      </div>
                    }
                  >
                    <SEO
                      title="Register"
                      description="Create a new account."
                      keywords="register"
                    />
                    <Register />
                  </Suspense>
                }
              />
              <Route
                path="/forgot-password"
                element={
                  <Suspense
                    fallback={
                      <div className="loader-container">
                        <div className="nb-spinner"></div>
                      </div>
                    }
                  >
                    <SEO
                      title="Forgot Password"
                      description="Reset your password."
                      keywords="forgot password"
                    />
                    <ForgotPassword />
                  </Suspense>
                }
              />

              {/* Funnels and Pricing */}

              <Route
                path="/sale"
                element={
                  <Suspense fallback={<div className="loader-container"><div className="nb-spinner"></div></div>}>
                    <SEO title="Sale" description="Explore our new sales for 2025." keywords="sale, offers" />
                    <Sale />
                  </Suspense>
                }
              />

              <Route
                path="/paylatersale"
                element={
                  <Suspense fallback={<div className="loader-container"><div className="nb-spinner"></div></div>}>
                    <SEO title="Buy Now Pay Later" description="Explore our new sales for 2025." keywords="sale, offers" />
                    <PayLaterSales />
                  </Suspense>
                }
              />


              <Route
                path="/package"
                element={
                  <Suspense fallback={<div className="loader-container"><div className="nb-spinner"></div></div>}>
                    <SEO title="The UBJ Sale" description="Get featured in The United Business Journal." keywords="sale, offers" />
                    <Package />
                  </Suspense>
                }
              />


{/* 
              <Route
                path="/tiktok"
                element={
                  <Suspense fallback={<div className="loader-container"><div className="nb-spinner"></div></div>}>
                    <SEO title="TikTok Sale" description="Get featured in 3 Major publications." keywords="sale, offers" />
                    <TikTokSale />
                  </Suspense>
                }
              />
              <Route
                path="/tony"
                element={
                  <Suspense fallback={<div className="loader-container"><div className="nb-spinner"></div></div>}>
                    <SEO title="Times Of New York Sale" description="Get featured in Times Of New York." keywords="sale, offers" />
                    <Tony />
                  </Suspense>
                }
              /> */}



              <Route
                path="/pricelist"
                element={
                  <Suspense fallback={<div className="loader-container"><div className="nb-spinner"></div></div>}>
                    <SEO title="Pricing" description="View our pricing plans." keywords="pricing, costs" />
                    <Price />
                  </Suspense>
                }
              />
              <Route
                path="/thanks"
                element={
                  <Suspense fallback={<div className="loader-container"><div className="nb-spinner"></div></div>}>
                    <SEO title="Thank You" description="Thank you for your business." keywords="thank you, response" />
                    <Thankyou />
                  </Suspense>
                }
              />

              {/* User Area */}
              <Route
                path="/user/*"
                element={
                  <Suspense
                    fallback={
                      <div className="loader-container">
                        <div className="nb-spinner"></div>
                      </div>
                    }
                  >
                    <ProtectedRoute
                      authenticated={isUserAuthenticated}
                      element={<UserArea />}
                    />
                  </Suspense>
                }
              />

              {/* Admin Area */}
              <Route
                path="/admin/*"
                element={
                  <Suspense
                    fallback={
                      <div className="loader-container">
                        <div className="nb-spinner"></div>
                      </div>
                    }
                  >
                    <ProtectedRoute
                      authenticated={isAdminAuthenticated}
                      element={<AdminArea />}
                    />
                  </Suspense>
                }
              />


<Route
  path="*"
  element={
    <Suspense
      fallback={
        <div className="loader-container">
          <div className="nb-spinner"></div>
        </div>
      }
    >
      <SEO
        title="Page Not Found"
        description="The page you are looking for does not exist."
        keywords="404, page not found"
      />
      <NotFound />
    </Suspense>
  }
/>



            </Routes>
          </GoogleAnalyticsTracker>
        </Router>
      </div>
    </HelmetProvider>
  );
}

export default App;
