import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';


console.log("JavaScript file loaded successfully");


const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  console.error("Root element with id 'root' not found.");
}

// Optional: Only include reportWebVitals if you need it for performance monitoring
// reportWebVitals();